import React, { useState, useEffect } from "react";
import tableImage from '../assets/images/chair.png';
import { Card, Modal, Button, Table, Collapse, message, Popconfirm } from 'antd';
import axios from 'axios';
import { DeleteOutlined, CheckOutlined } from "@ant-design/icons";
import './BillList.css';

const TableList = ({ table }) => {
    const { Meta } = Card;
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [orders, setOrders] = useState([]);
    const [tableNumber, setTableNumber] = useState(table.table_number);
    const [tableOrders, setTableOrders] = useState({});
    const [showReceiptModal, setShowReceiptModal] = useState(false);
    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const [taxes, setTaxes] = useState([]);

    const onChange = (key) => {
        console.log(key);
    };

    const getAllOrders = async () => {
        try {
          const { data } = await axios.get("/api/orders/get-order");
          setOrders(data);
        } catch (error) {
          console.log(error);
        }
      };

    useEffect(() => {
        const fetchTableOrders = async () => {
            try {
                const { data: ordersData } = await axios.get(`/api/orders/get-bill?table=${tableNumber}`);
                const tableOrdersMap = {};
                ordersData.forEach(order => {
                    const { order_tablenumber } = order;
                    if (!tableOrdersMap[order_tablenumber]) {
                        tableOrdersMap[order_tablenumber] = [];
                    }
                    tableOrdersMap[order_tablenumber].push(order);
                });
                setTableOrders(tableOrdersMap);
            } catch (error) {
                console.log('Error fetching orders:', error);
            }
        };
        fetchTaxes();
        fetchTableOrders();
    }, [tableNumber]);

    const fetchData = async (tableNumber) => {
        try {
            const ordersData = tableOrders[tableNumber] || [];
            setOrders(ordersData);
        } catch (error) {
            console.log('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData(tableNumber);
    }, [tableNumber, tableOrders]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const paramTableNumber = urlParams.get('table');

        // If the table number in the query parameter matches the current table, open the modal
        if (paramTableNumber && paramTableNumber === String(tableNumber)) {
            setOpen(true);
        }
    }, [tableNumber]);

    const handleDeleteOrder = async (record) => {
    try {
      await axios.post("http://localhost:8080/api/orders/delete-order", { orderArrayId: record._id });
      message.success('Order Deleted Successfully')
      getAllOrders();
    } catch (error) {
      message.error('Something Went Wrong')
      console.log(error);
    }
  }

    const handleCancel = () => {
        setOpen(false);
    };

    const handleOk = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setOpen(false);
        }, 3000);
    };

    const handleOkClick = async () => {
        try {
            // Get unique oids from orders
            const uniqueOids = [...new Set(orders.map(order => order.oid))];

            // Loop through each unique oid and update its status to "Paid"
            const updatePromises = uniqueOids.map(oid => {
                return axios.put('/api/orders/edit-order', {
                    orderId: oid,
                    order_progressstatus: "Paid"
                });
            });

            // Wait for all update requests to complete
            const results = await Promise.all(updatePromises);

            // Check if all requests were successful
            if (results.every(response => response.status === 201)) {
                alert('All orders updated to Paid!');
                window.location.reload();
            } else {
                console.error('Some orders failed to update');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const fetchTaxes = async () => {
        try {
            const response = await axios.get('/api/taxes/get-taxes');
            setTaxes(response.data);
        } catch (error) {
            console.error("Error fetching taxes:", error);
            message.error("Failed to fetch taxes.");
        }
    };

    const { Panel } = Collapse;

    const Receipt = ({ orders }) => {
        const orderItems = orders.flatMap(order => order.result);

        return (
            <div className="receipt-container">
                <div className="receipt-header">
                    <h1>Receipt</h1>
                </div>
                <div className="receipt-details">
                    <p><strong>Table Number:</strong> {orders[0]?.order_tablenumber}</p>
                    <p><strong>Date:</strong> {new Date().toLocaleString()}</p>
                </div>
                <table className="receipt-table">
                    <thead>
                        <tr>
                            <th>Item</th>
                            <th>Quantity</th>
                            <th>Price</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orderItems.map((item, index) => (
                            <tr key={index}>
                                <td>{item.orderArray_productName}</td>
                                <td>{item.orderArray_quantity}</td>
                                <td>{item.orderArray_price}</td>
                                <td>{(Number(item.orderArray_quantity) * Number(item.orderArray_price)).toFixed(2)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className="receipt-subtotal">
                    Total Price: RM{" "}
                    <span>
                        {calculateTotalWithoutTaxes(orders)}
                    </span>
                </div>

                <div className="receipt-subtotal">
                    Total Tax: RM{" "}
                    <span>
                        {(calculateTotalWithTaxes(orders) - calculateTotalWithoutTaxes(orders)).toFixed(2)}
                    </span>
                </div>

                <div className="receipt-total">
                    <p>--------------------------</p>
                    Total Price with Taxes: RM{" "}
                    <span>
                        {calculateTotalWithTaxes(orders)}
                    </span>
                </div>

                <div className="receipt-footer">
                    <p>Thank you for your order!</p>
                </div>
            </div>
        );
    };

    const handleShowReceipt = (orderId) => {
        setSelectedOrderId(orderId); // Set the selected order ID when showing the receipt
        setShowReceiptModal(true);
    };

    const calculateTotalWithTaxes = (order) => {
        const orderItems = orders.flatMap(order => order.result);
        const totalWithoutTax = orderItems.reduce(
            (acc, curr) => acc + curr.orderArray_price * curr.orderArray_quantity,
            0
        );
        const totalTax = taxes.reduce((acc, tax) => acc + totalWithoutTax * (tax.rate / 100), 0);
        return (totalWithoutTax + totalTax).toFixed(2);
    };

    const calculateTotalWithoutTaxes = (order) => {
        const orderItems = orders.flatMap(order => order.result);
        const totalWithoutTax = orderItems.reduce(
            (acc, curr) => acc + curr.orderArray_price * curr.orderArray_quantity,
            0
        );

        return (totalWithoutTax).toFixed(2);
    };

    return (
        <div>
            <Card
                hoverable
                style={{ width: 230, marginBottom: 20 }}
                cover={<img alt={table.table_number} src={tableImage} />}
                onClick={() => setOpen(true)} // This allows opening the modal when clicking the card as well
            >
                <Meta
                    title={`Table ${tableNumber}`}
                />
            </Card>

            <Modal
                open={open}
                title={`Table ${tableNumber}`}
                onOk={handleOk}
                onCancel={handleCancel}
                width={1000}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Back
                    </Button>,
                    <Button
                        key="show-receipt"
                        type="primary"
                        loading={loading}
                        onClick={() => handleShowReceipt(orders[0]?._id)}
                    >
                        Show Receipt
                    </Button>,
                ]}
            >
                <Collapse defaultActiveKey={orders.map((order) => order.oid)} onChange={onChange}>
                    {orders.map((order) => (
                        <Panel header={<strong>Order ID: #{order.oid}</strong>} key={order.oid}>
                            <Table
                                bordered
                                columns={[
                                    {
                                        title: 'No',
                                        key: 'index',
                                        render: (text, record, recordIndex) => (
                                            <span>{recordIndex + 1}</span>
                                        ),
                                    },
                                    {
                                        title: "Food Name",
                                        dataIndex: "orderArray_productName",
                                    },
                                    {
                                        title: "Price(RM)",
                                        dataIndex: "orderArray_price",
                                        render: (orderArray_price) => {
                                            const formattedPrice = orderArray_price.toLocaleString("en-MY", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            });
                                            return `RM${formattedPrice}`;
                                        },
                                    },
                                    {
                                        title: "Quantity",
                                        dataIndex: "orderArray_quantity",
                                    },
                                    {
                                        title: "Total Price(RM)",
                                        render: (_, record) => {
                                            const totalPrice = (record.orderArray_price * record.orderArray_quantity).toFixed(2);
                                            return `RM${totalPrice}`;
                                        },
                                    },
                                    {
                                        title: "Actions",
                                        render: (_, record) => (
                                            <div>
                                                <CheckOutlined
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => handleShowReceipt(order._id)} // Use the correct order ID
                                                />
                                                <Popconfirm
                                                    title="Are you sure you want to delete this record?"
                                                    onConfirm={() => handleDeleteOrder(record)}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <DeleteOutlined
                                                        style={{ cursor: "pointer", marginLeft: 8 }}
                                                    />
                                                </Popconfirm>
                                            </div>
                                        ),
                                    },
                                ]}
                                dataSource={order.result || []}
                            />
                            <div style={{ textAlign: "right", marginTop: "1rem", fontSize: "1.3em" }}>
                                Total Price: RM{" "}
                                <span style={{ fontWeight: "bold", fontSize: "1.1em" }}>
                                    {calculateTotalWithoutTaxes(order)}
                                </span>
                            </div>

                            <div style={{ textAlign: "right", fontSize: "1.3em" }}>
                                Total Tax: RM{" "}
                                <span style={{ fontWeight: "bold", fontSize: "1.1em" }}>
                                    {(calculateTotalWithTaxes(order) - calculateTotalWithoutTaxes(order)).toFixed(2)}
                                </span>
                            </div>

                            <div style={{ textAlign: "right", marginTop: "1rem", fontSize: "1.5em" }}>
                                Total Price with Taxes: RM{" "}
                                <span style={{ fontWeight: "bold", fontSize: "1.3em" }}>
                                    {calculateTotalWithTaxes(order)}
                                </span>
                            </div>
                        </Panel>
                    ))}
                </Collapse>
            </Modal>

            <Modal
                title="Receipt"
                visible={showReceiptModal}
                onCancel={() => setShowReceiptModal(false)}
                onOk={() => {
                    setShowReceiptModal(false);
                    window.print();
                    handleOkClick();
                }}
            >
                <Receipt orders={orders} />
            </Modal>
        </div>
    );
};

export default TableList;